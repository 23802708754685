import { getTokenPlayload, validJSON } from "common/utils";
import {
    setTotalFacilitiesRegistered,
    userLogin,
} from "pages/Verify/user.slice";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import CONSTANTS from "common/constants";
import APIUtils from "utils/API.utils";
import { logger } from "utils/logger.utils";
import config from "../config";
import LocalStore from "../common/localStore";

const API = new APIUtils();

export interface APIResponse {
    success: boolean;
    error: {
        code: string;
        message: string;
        details: [
            {
                target: string;
                message: string;
            }
        ];
    };
    data: any;
    statusCode: number;
}

export const userPatchApi = async (user: any): Promise<APIResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let reqBody;
            if (user.phoneNumber) {
                reqBody = {
                    ssoId: user.UID,
                    phoneNumber: user.phoneNumber,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                };
            } else {
                reqBody = {
                    ssoId: user.UID,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                };
            }

            const APIRes = await API.PATCH(
                `${config[config.env].apiEndpoints.accounts}/users`,
                reqBody
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getNavigationApi = async (): Promise<APIResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/navigation`
            );

            if (APIRes.success === true) {
                localStorage.setItem(
                    "isProgramApproved",
                    APIRes.data.isProgramApproved
                );
                localStorage.setItem(
                    "isHospitalDeActiveOrDeleted",
                    APIRes.data.isHospitalDeActiveOrDeleted
                );
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getNavigationByHospitalId = async (
    hospitalId: number | string
): Promise<APIResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalId}/navigation`
            );

            if (APIRes.success === true) {
                localStorage.setItem(
                    "isProgramApproved",
                    APIRes.data.isProgramApproved
                );
                localStorage.setItem(
                    "isHospitalDeActiveOrDeleted",
                    APIRes.data.isHospitalDeActiveOrDeleted
                );
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const hospitalRegistrationHistory = async (
    hospitalID: number | string
): Promise<APIResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/hospitals/${hospitalID}/register/history`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const userApi = async (user: any): Promise<APIResponse> => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", user?.ssoAccessToken);
    let requestBody: any;
    const userType = user?.ssoUser?.userType;

    switch (userType) {
        case CONSTANTS.REGISTRY:
            requestBody = {
                userType: CONSTANTS.REGISTRY,
            };
            break;
        case CONSTANTS.CORPORATION:
            requestBody = {
                userType: CONSTANTS.CORPORATION,
            };
            break;
        default:
            break;
    }
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
    };

    return new Promise((resolve, reject) => {
        fetch(
            `${config[config.env].apiEndpoints.accounts}/users`,
            requestOptions
        )
            .then((result) => result.text())
            .then(async (result) => {
                if (validJSON(result)) {
                    const userResponse = JSON.parse(result);
                    if ([200, 201].indexOf(userResponse.statusCode) >= 0) {
                        if (localStorage.getItem("userAccessToken")) {
                            await userSessionTokenApi();
                        }
                        localStorage.setItem(
                            "userAccessToken",
                            userResponse.data.accessToken
                        );
                        localStorage.setItem(
                            "refreshToken",
                            userResponse.data.refreshToken
                        );
                        userResponse.data.tokenPayload = getTokenPlayload(
                            userResponse.data.accessToken
                        );
                        LocalStore.set(
                            "hospitalId",
                            userResponse.data.tokenPayload.hospital_id
                        );
                        LocalStore.set(
                            "countryId",
                            userResponse.data.tokenPayload.country_id
                        );
                        LocalStore.set(
                            "registryId",
                            userResponse.data.tokenPayload.registry_id
                        );
                        store.dispatch(userLogin(userResponse?.data));
                        resolve(userResponse);
                    } else {
                        throw userResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((error) => {
                error.suppress = true;
                const err: any = error;
                store.dispatch(showToast(err));
                reject(error);
            });
    });
};

function parseJwt(token: any) {
    if (token) {
        const base64Url = token?.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                ?.split("")
                .map(function (c) {
                    return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
                })
                .join("")
        );
        return JSON.parse(jsonPayload);
    }
    return {};
}

export const userAccessTokenRefresh = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
        const ssoId = window.localStorage.getItem("userAccessToken")
            ? parseJwt(window.localStorage.getItem("userAccessToken")).sso_id
            : null;
        if (!ssoId) {
            reject(new Error("sso id not available"));
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                ssoId,
                firstName: "test",
                lastName: "test",
                email: "test@gmail.com",
            }),
        };
        fetch(
            `${config[config.env].apiEndpoints.accounts}/users`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                const userResponse = JSON.parse(result);
                resolve(userResponse);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const userSessionTokenApi = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.DELETE(
                `${config[config.env].apiEndpoints.accounts}/users/signout`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            logger("error is :: ", error);
            resolve(true);
        }
    });
};

export const getUserList = async (
    hospitalId: string | number,
    pageNumber: number,
    pageSize: number,
    isEUP: any,
    inputUserValue: any = ""
): Promise<APIResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let apiEndPoint;
            if (inputUserValue) {
                apiEndPoint = `${
                    config[config.env].apiEndpoints.accounts
                }/users/userManagement/hospitals?user=${encodeURIComponent(
                    inputUserValue
                )}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
            } else {
                apiEndPoint = `${
                    config[config.env].apiEndpoints.accounts
                }/users/userManagement/hospitals?pageSize=${pageSize}&pageNumber=${pageNumber}`;
            }
            if (isEUP) {
                apiEndPoint = `${apiEndPoint}&hospitalId=${hospitalId}`;
            }
            const response = await API.GET(apiEndPoint);
            resolve(response);
            if (response.success == true) {
                resolve(response);
            } else {
                throw response.error;
            }
        } catch (error: any) {
            const err: any = error;
            store.dispatch(showToast(err));
            logger("error is ::", error);
            reject(error);
        }
    });
};

export const getUserHandShake = async (): Promise<APIResponse> => {
    return new Promise(async (res, rej) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", CONSTANTS.AUTHORIZERALLOWTOKEN);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        return new Promise((resolve, reject) => {
            fetch(
                `${config[config.env].apiEndpoints.accounts}/users/handShake`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    if (validJSON(result)) {
                        const maintenanceResponse = JSON.parse(result);
                        if (maintenanceResponse.statusCode == 200) {
                            resolve(maintenanceResponse);
                        } else {
                            throw maintenanceResponse.error;
                        }
                    } else {
                        throw new Error("Invalid json string in the response");
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    });
};

export const getUserHospitals = async (
    pageNumber: number,
    pageSize: number,
    searchKey: string = ""
): Promise<APIResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/users/hospitals?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKeyword=${encodeURIComponent(
                    searchKey
                )}`
            );

            if (APIRes.success === true) {
                store.dispatch(
                    setTotalFacilitiesRegistered(
                        APIRes.data?.totalFacilityCount
                    )
                );
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getSSOUrl = async (): Promise<APIResponse> => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", CONSTANTS.AUTHORIZERALLOWTOKEN);
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return new Promise((resolve, reject) => {
        fetch(
            `${config[config.env].apiEndpoints.accounts}/users/generateSSOUrl`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                if (validJSON(result)) {
                    const tokenResponse = JSON.parse(result);
                    if (tokenResponse.statusCode == 200) {
                        resolve(tokenResponse);
                    } else {
                        throw tokenResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getTokenAndLogin = async (
    ssoCode: any,
    ssoVerifier: any,
    userType: any
): Promise<APIResponse> => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", CONSTANTS.AUTHORIZERALLOWTOKEN);
    const requestBody = {
        ssoCode,
        ssoVerifier,
        userType,
    };
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
    };
    return new Promise((resolve, reject) => {
        fetch(
            `${
                config[config.env].apiEndpoints.accounts
            }/users/getTokenAndLogin`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                if (validJSON(result)) {
                    const tokenResponse = JSON.parse(result);
                    if (tokenResponse.statusCode == 200) {
                        resolve(tokenResponse);
                    } else {
                        throw tokenResponse.error;
                    }
                } else {
                    throw new Error("Invalid json string in the response");
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};
